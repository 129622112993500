import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stores/store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";
import VCalendar from "v-calendar";
import * as VueGoogleMaps from "vue2-google-maps";
import VueFriendlyIframe from "vue-friendly-iframe";
import VueScrollTo from "vue-scrollto";
import DatePicker from "@/components/common/DatePicker";
import ModuleSettingHelper from "@/_helpers/moduleSettingsHelper";
import CommonHelper from "@/_helpers/commonHelper";
import DateHelper from "@/_helpers/DateHelper";
import RestrictHelper from "@/_helpers/RestrictHelper";
import PhonenumberInput from "@/components/common/PhonenumberInput";
import GoogleMapsHelper from "@/_helpers/mapsHelper";
import Particles from "particles.vue";

import ECharts from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  ScatterChart,
  BarChart,
  LineChart,
  GaugeChart,
  PieChart,
} from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
  MarkLineComponent,
  PolarComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  GaugeChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
  MarkLineComponent,
  ScatterChart,
  PolarComponent,
]);

Vue.component("e-chart", ECharts);
Vue.component("phone-number-input", PhonenumberInput);
Vue.component("vue-friendly-iframe", VueFriendlyIframe);
Vue.component("DatePicker", DatePicker);

Vue.mixin(ModuleSettingHelper);
Vue.mixin(DateHelper);
Vue.mixin(RestrictHelper);
Vue.mixin(GoogleMapsHelper);
Vue.mixin(CommonHelper);

Vue.config.productionTip = false;
Vue.use(Particles);

Vue.use(VueScrollTo);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    plugins: ["places", "drawing", "visualization", "geometry"],
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
  installComponents: true,
});

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
